import React, { useState, useEffect } from 'react'
import InnerImageZoom from 'react-inner-image-zoom'
import LazyLoad from 'react-lazyload';
import playImage from '../../images/play.png';
import styled from 'styled-components';
import PlaceOrder from './PlaceOrder.tsx';
import uuid4 from "uuid4";

//make the image cover without distorting it
const ThumbImage = styled.img`
    object-fit: cover;
    width: 100%;    
    height: 100%;
`;

interface ImageElement {
    caption: string;
    thumb: string;
    big: string;
    contentType: string;
}


function ListingImages({ data }) {
    const [focusedImage, setFocusedImage] = useState<null | ImageElement>(null);
    const [title, setTitle] = useState<null | JSX.Element>(null);
    const [description, setDescription] = useState<null | JSX.Element>(null);
    const [footnote, setFootnote] = useState<null | JSX.Element>(null);
    const [gallery, setGallery] = useState<ImageElement[]>([]);
    const [previewElements, setPreviewElements] = useState<null | JSX.Element[]>(null);

    function next() {
        if (!focusedImage) return;
        const currentIndex = gallery.indexOf(focusedImage);
        const length = gallery.length;
        if (currentIndex < length - 1) {
            setFocusedImage(gallery[currentIndex + 1]);
        }
    }

    function prev() {
        if (!focusedImage) return;
        const currentIndex = gallery.indexOf(focusedImage);
        if (currentIndex > 0) {
            setFocusedImage(gallery[currentIndex - 1]);
        }
    }

    let prevBtn;
    let nextBtn;

    if (gallery.length > 1 && focusedImage) {
        prevBtn = (gallery.indexOf(focusedImage) > 0)
            ? (<button onClick={() => prev()}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" /></svg>
            </button>)
            : (<button className='inactive' disabled>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" /></svg>
            </button>);
        nextBtn = (gallery.indexOf(focusedImage) < gallery.length - 1)
            ? (<button onClick={() => next()}>

                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" /></svg>
            </button>)
            : (<button className='inactive' disabled>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" /></svg>
            </button>);
    }

    interface Listing {
        _id: string;
        uuid: string;
        name: string;
        dimentions: string;
        price: number;
        imageUrl: string;
    }

    const generateListings = (data): Listing[] => {

        return data.availableDimentionsCollection.items.map((item) => {
            return {
                _id: item._id,
                uuid: uuid4(),
                dimentions: item.dimentions,
                price: item.price,
                name: data.title,
                imageUrl : data.mainImage.url
            }
        })
    }


    useEffect(() => {
        setTitle(data.hasOwnProperty('title') ? (<h2>{data['title']}</h2>) : null);
        setDescription(data.hasOwnProperty('description') ? (<p>{data['description']}</p>) : null);
        setFootnote(data.hasOwnProperty('footnote') ? (<small className='footNote'>{data['footnote']}</small>) : null);

        const mainImageObj = data.hasOwnProperty('mainImage') ? data['mainImage'] : null;
        if (mainImageObj) {
            const main = {
                caption: mainImageObj['description'],
                thumb: mainImageObj['url'] + "?fm=avif&w=150",
                big: mainImageObj['url'] + "?fm=avif&w=900",
                contentType: mainImageObj['contentType']
            };

            setFocusedImage(main);

            const galleryObj = data.hasOwnProperty('galleryCollection') ? (data['galleryCollection']['items']) : null;

            let galleryThumbnails = [main];
            if (galleryObj) {
                Object.entries(galleryObj).forEach((el) => {
                    const entry = el[1] as { [key: string]: string };

                    const caption = entry['description'];
                    const thumbSrc = entry['url'] + "?fm=avif&w=150";
                    const bigSrc = entry['url'] + "?fm=avif&w=900";
                    const contentType = entry['contentType'];

                    galleryThumbnails.push({ caption: caption, thumb: thumbSrc, big: bigSrc, contentType: contentType });
                });
            }
            setGallery(galleryThumbnails)
        }
    }, [data]);

    useEffect(() => {
        if (gallery.length > 1) {
            setPreviewElements(gallery.map((val, key) => {
                const isImage = val['contentType'].includes('image');

                return (
                    <LazyLoad key={key} height={145}>
                        <ThumbImage
                            onClick={() => {
                                setFocusedImage(val)
                            }}
                            src={isImage ? val['thumb'] : playImage}
                            alt={val['caption']}
                        />
                    </LazyLoad>
                )

            }))
        }

    }, [gallery]);

    /*let user navigate images with keypress*/
    useEffect(() => {
        let shouldHandleKeyDown = true;
        document.onkeydown = (event) => {
            if (!shouldHandleKeyDown) return;
            if (event.code === 'ArrowLeft') {
                prev();
            }
            else if (event.code === 'ArrowRight') {
                next();
            }
            shouldHandleKeyDown = false;
        };
        document.onkeyup = () => {
            shouldHandleKeyDown = true;
        }

    }, [focusedImage]);

    if (focusedImage) {
        return (
            <div className='modalData'>
                <div className={'focused'}>
                    <div className='focusedContainer'>
                        <div>
                            {
                                focusedImage['contentType'].includes('video') ?
                                    <video controls>
                                        <source src={focusedImage['big']} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    :
                                    <InnerImageZoom
                                        src={focusedImage['big']}
                                        alt='focused'
                                    />
                            }

                            <div className='navigateModal'>
                                {prevBtn}
                                {nextBtn}
                            </div>
                        </div>

                        <small>{focusedImage['caption']}</small>
                    </div>

                </div>

                <div className='projectInfo'>
                    {title}
                    {data.availableForSale && <PlaceOrder Listings={generateListings(data)} />}
                    {description}
                    {footnote}
                    {!data.availableForSale && <h5>* Prints are currently out of stock</h5>}
                    <div className={'previews'}>
                        {previewElements}
                    </div>
                </div>
            </div>
        )
    } else {
        return <div className='loading'> </div>
    }

}

export default ListingImages;