import React, { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { fetchBlogPost, fetchAsset } from '../Utils/Api.tsx';
import { useParams } from 'react-router-dom';
import { BLOCKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import LoadingSpinner from '../Components/LoadingSpinner.tsx';

const Wrapper = styled.div`
    padding: 20px 20px 90px 20px;

    > img {
        width: 100%;
        max-height: 500px;
        margin-bottom: 20px;
        object-fit: cover;

    }

    h1 {
        margin-bottom: 20px;
    }
    h2, h3, h4, h5, h6 {
        margin-bottom: 10px;
        margin-top: 40px;
    }
    p {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }
    ul, ol {
        margin-bottom: 10px;
        padding-left: 40px;
    }
    a {
        color: #94c8ff;
    }
`;

const ImageAsset = styled.div`
    text-align: center;
    margin: 20px 0;
    img {
        max-width: 100%;
        max-height: 500px;
        margin: auto;
        display: block;
        object-fit: cover;
    }

    p {
        font-size: 14px;
        color: #8b8b8b;
        padding: 5px 0;
    }
`;

const BlogPost: React.FC = () => {
    const params = useParams();
    const id = params.id as string;

    const [blogPost, setBlogPost] = useState<any>(null);
    const [embeddedAssets, setEmbeddedAssets] = useState<{ [key: string]: any }>({});
    const [fetchedAllAssets, setFetchedAllAssets] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchBlogPost(id);
            setBlogPost(data.blogPost);

            const assetMap: { [key: string]: any } = {};
            const embeddedAssetNodes = data.blogPost.content.json.content.filter(
                (node: any) => node.nodeType === BLOCKS.EMBEDDED_ASSET
            );

            for (const node of embeddedAssetNodes) {
                const assetId = node.data.target.sys.id;
                const asset = await fetchAsset(assetId);
                assetMap[assetId] = asset;
            }

            setEmbeddedAssets(assetMap);
            setFetchedAllAssets(true);
        };

        fetchData();
    }, [id]);

    const renderOptions = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                const assetId = node.data.target.sys.id;
                const assetData = embeddedAssets[assetId];

                if (!assetData) (< LoadingSpinner />)

                return (
                    <ImageAsset>
                        <img src={assetData.asset.url + "?fm=avif"} alt={assetData.asset.title || 'Asset'} />
                        <p>{assetData.asset.title}</p>
                    </ImageAsset>
                );
            },
        },
    };

    return fetchedAllAssets ? (
        <Wrapper className="blog-post">
            {blogPost && (
                <>
                    <h1>{blogPost.title}</h1>
                    <img src={blogPost.mainImage.url + "?fm=avif"} alt={blogPost.title} />
                    {documentToReactComponents(blogPost.content.json, renderOptions)}
                </>
            )}
        </Wrapper>
    ) : (<LoadingSpinner />);
};

export default BlogPost;
