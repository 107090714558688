const baseUrl = `https://graphql.contentful.com/content/v1/spaces/2ldd9mf1rd6h/environments/master`;
const token = 'SlNuXj2etoljM-KN0735U3KIyUqkDBeVInf8S0r70KA';

const fetchData = async (query: string) => {
    try {
        const response = await fetch(baseUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ query }),
        });
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};


export const fetchGalleryEntry = async (id: string) => {
    const query = `
    query {
        galleryEntries(id: "${id}") {
            title, 
            mainImage {
                contentType, 
                url, 
                description, 
                title
            }, 
            footnote,
            description, 
            availableForSale,
            galleryCollection {
                items {
                    contentType, 
                    url, 
                    description,
                    title
                }
            },
            availableDimentionsCollection{
                items{
                    dimentions,
                    price,
                    _id
                }
            }
        }
    }`;
    return await fetchData(query);
};

export const fetchBioData = async () => {
    const bioQuery = `{
        bioCollection {
            items {
                profilePicture { url },
                country,
                education,
                email,
                name,
                phone,
                born,
                biography
            }
        }
    }`;
    return await fetchData(bioQuery);
};

export const fetchLanguagesData = async () => {
    const languagesQuery = `{
        spokenLanguagesCollection {
            items { language }
        }
    }`;
    return await fetchData(languagesQuery);
};

export const fetchCvEntries = async () => {
    const cvEntriesQuery = `{
        titleCollection(order: [startYear_DESC]) {
            items {
                title,
                description,
                startYear,
                endYear,
                category,
                extraDescription
            }
        }
    }`;
    return await fetchData(cvEntriesQuery);
};

export const fetchGalleryCollection = async () => {
    const galleryCollectionQuery = `{
        galleryEntriesCollection(order: order_DESC) {
            items {
                sys { id },
                order,
                subTitle,
                mainImage { url }
            }
        }
    }`;
    return await fetchData(galleryCollectionQuery);
};

export const fetchBlogPosts = async () => {
    const blogPostsQuery = `{
        blogPostCollection(order: sys_publishedAt_DESC) {
            items {
                sys { id, publishedAt },
                mainImage { url },
                title,
                summary,
            }
        }
    }`;
    return await fetchData(blogPostsQuery);
}

export const fetchBlogPost = async (id: string) => {
    const blogPostQuery = `{
        blogPost(id: "${id}") {
            title,
            sys { id, publishedAt },
            mainImage { url },
            content { json }
        }
    }`;
    return await fetchData(blogPostQuery);
}

export const fetchAsset = async (id: string) => {
    const assetQuery = `{
        asset(id: "${id}") {
            url,
            title,
        }
    }`;
    return await fetchData(assetQuery);
}