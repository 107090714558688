import React from "react";
import { useCart } from "../../../Context/CartContext.tsx";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,

} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Payment from './Payment.tsx';
import Button from '@mui/material/Button';
const FinalInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 40px;
`;

const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
`;

const QuantityTweaker = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
const StyledButton = styled(Button)`
    display: flex;
    gap: 8px;
    font-weight: 600!important;

    && {
        background-color: #9b0000; 
        color: #ffffff;
    
        &:hover {
            background-color: #c90000;
        }
    }
`;

interface GroupedItem {
    _id: string;
    name: string;
    dimentions: string;
    price: number;
    imageUrl: string;
    quantity: number;
}


interface Listing {
    _id: string;
    uuid: string;
    name: string;
    dimentions: string;
    price: number;
    imageUrl: string;
}

const groupCartItems = (items: Listing[]): GroupedItem[] => {
    const groupedItems: { [key: string]: GroupedItem } = {};

    items.forEach((item) => {
        const groupKey = `${item._id}-${item.dimentions}`;
        if (groupedItems[groupKey]) {
            groupedItems[groupKey].quantity += 1;
        } else {
            groupedItems[groupKey] = {
                _id: item._id,
                name: item.name,
                dimentions: item.dimentions,
                price: item.price,
                imageUrl: item.imageUrl,
                quantity: 1,
            };
        }
    });

    return Object.values(groupedItems);
};

interface Props {
    setIsInCheckout: React.Dispatch<React.SetStateAction<boolean>>;
    setOrderInfo: React.Dispatch<React.SetStateAction<any>>;
}

const CartTable: React.FC<Props> = ({ setIsInCheckout, setOrderInfo }) => {
    const { items, removeItem, addItem } = useCart();

    const shippingCost = 99;
    const groupedItems = groupCartItems(items);

    const total = groupedItems.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
    );
    const totalWithShipping = total + shippingCost;

    const increaseQuantity = (item: GroupedItem) => {
        const selectedItem = items.find((i) => i._id === item._id);
        if (selectedItem) {
            addItem(selectedItem);
        }
    }

    const decreaseQuantity = (item: GroupedItem) => {
        const selectedItem = items.find((i) => i._id === item._id);
        if (selectedItem) {
            removeItem(selectedItem._id);
        }
    }

    const goToShippingDetails = () => {
        setIsInCheckout(true);

        const flattenedItems = groupedItems.reduce((acc, item, index) => {
            const itemPrefix = `item${index + 1}-`;
            return {
                ...acc,
                [`${itemPrefix}name`]: item.name,
                [`${itemPrefix}dimentions`]: item.dimentions,
                [`${itemPrefix}price`]: item.price,
                [`${itemPrefix}imageUrl`]: item.imageUrl,
                [`${itemPrefix}quantity`]: item.quantity,
            };
        }, {});

        const orderInfo = {
            ...flattenedItems,
            total: totalWithShipping,
            shippingCost: shippingCost,
        };

        setOrderInfo(orderInfo);
    };


    return (
        <Wrapper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>dimentions</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">Remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupedItems.map((item) => (
                            <TableRow key={`${item._id}-${item.dimentions}`}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.dimentions}</TableCell>
                                <TableCell >{item.price},-</TableCell>
                                <TableCell >
                                    <QuantityTweaker>
                                        <IconButton
                                            size="small"
                                            onClick={() => decreaseQuantity(item)}
                                            disabled={item.quantity === 1}
                                            aria-label="remove item"
                                        >
                                            <RemoveIcon />
                                        </IconButton>

                                        <h5>{item.quantity}</h5>
                                        <IconButton
                                            size="small"
                                            onClick={() => increaseQuantity(item)}

                                            aria-label="add item"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </QuantityTweaker>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => removeItem(item._id)}
                                        color="error"
                                        aria-label="remove item"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <FinalInfo>
                <Typography variant="body2" color="textSecondary">
                    Shipping: 99 NOK
                </Typography>
                <Typography variant="h6">Total: {totalWithShipping} NOK</Typography>
            </FinalInfo>
            <Payment />
            <StyledButton onClick={goToShippingDetails}>
                Shipping details
            </StyledButton>
        </Wrapper>
    );
};

export default CartTable;
