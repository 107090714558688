// Bio.tsx
import React, { useEffect, useState } from 'react';
import CvView from '../Components/CvView.jsx';
import { fetchBioData, fetchLanguagesData, fetchCvEntries } from '../Utils/Api.tsx';
import LoadingSpinner from '../Components/LoadingSpinner.tsx';

function Bio() {
    const [bioData, setBioData] = useState(null);
    const [languagesData, setLanguagesData] = useState(null);
    const [cvData, setCvData] = useState(null);

    useEffect(() => {
        const loadBioData = async () => {
            const bioResult = await fetchBioData();
            const languagesResult = await fetchLanguagesData();
            const cvResult = await fetchCvEntries();

            setBioData(bioResult?.bioCollection.items[0]);
            setLanguagesData(languagesResult?.spokenLanguagesCollection.items);
            setCvData(cvResult?.titleCollection.items);
        };

        loadBioData();
    }, []);

    if (bioData && languagesData && cvData) {
        return <CvView bio={bioData} languages={languagesData} cvEntries={cvData} />;
    } else {
        return ( <LoadingSpinner />
        );
    }
}

export default Bio;
