import React, { useState, useEffect, useMemo } from 'react';
import ImageWithTextOnHover from '../ImageWithTextOnHover.tsx';


interface FormattedSource {
    id: string;
    subtitle: string;
    url: string;
}

interface props {
    sources: any[];
    pageLimit?: number;
}
function AllListingsGallery({ sources, pageLimit }: props) {
    const formatSources = (): FormattedSource[] => {
        return sources.map(row => ({
            id: row.sys.id,
            subtitle: row.subTitle,
            url: `${row.mainImage.url}?fm=avif&w=600`,
        }));
    };

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [visibleSources, setVisibleSources] = useState<FormattedSource[]>([]);
    const formattedSources = useMemo(formatSources, [sources]);

    const limit = pageLimit || formattedSources.length;
    const numberOfPages = Math.ceil(formattedSources.length / limit);


    useEffect(() => {
        const start = currentPage * limit;
        const end = start + limit;
        setVisibleSources(formattedSources.slice(start, end));
    }, [currentPage, limit, formattedSources]);


    const galleryElements = visibleSources.map((elem: any, index) => (
        <ImageWithTextOnHover
            key={index}
            id={elem.id}
            imgUrl={elem.url}
            text={elem.subtitle}
        />
    ));

    return (
        <div className='galleryComponent'>
            <div className='galleryContainer'>
                {galleryElements}
            </div>
            {numberOfPages > 1 && (
                <div className='galNav'>
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
                        disabled={currentPage === 0}
                    >
                        &#8592;
                    </button>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, numberOfPages - 1))}
                        disabled={currentPage === numberOfPages - 1}
                    >
                        &#8594;
                    </button>
                </div>
            )}
        </div>
    );
}

export default AllListingsGallery;
