import React, { useState, useEffect } from 'react';
import Gall from '../Components/Listings/AllListingsGallery.tsx';
import { fetchGalleryCollection } from '../Utils/Api.tsx';
import LoadingSpinner from '../Components/LoadingSpinner.tsx';

const Gallery = () => {
    const [restData, setRestData] = useState(null);

    useEffect(() => {
        const loadGalleryData = async () => {
            const result = await fetchGalleryCollection();
            setRestData(result?.galleryEntriesCollection.items);
        };

        loadGalleryData();
    }, []);

    return ( restData ? <Gall sources={restData} /> : <LoadingSpinner /> );
}

export default Gallery;
