import React, { useState } from 'react';
import {
    TextField,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import styled from 'styled-components';
import Grid from '@mui/material/Grid2';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';

const Wrapper = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 350px;
    background-color: #1a1a1a;
    padding: 15px;
    border-radius: 15px;
    z-index: 1000;


    @media (max-width: 600px) {
        width: 100%;
        border-radius: 15px 15px 0 0;
        bottom: 0;
        right: 0;
        margin: 0;
    }
`;

const BubbleButton = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #9b0000;
    border-radius: 50%;

    &:hover {
        background-color: #c90000;
    }

    & svg {
        color: #ffffff;
    }
`;

const StyledTextField = styled(TextField)``;

const StyledButton = styled(Button)`
    display: flex;
    gap: 8px;
    font-weight: 600!important;
    && {
        background-color: #9b0000; 
        color: #ffffff;
    
        &:hover {
            background-color: #c90000;
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`;

const ChatBubble: React.FC = () => {
    const [formValues, setFormValues] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const [open, setOpen] = useState(false);
    const [result, setResult] = React.useState("");


    const validateField = (name: string, value: string) => {
        let error = '';

        if (name === 'name' && !value) {
            error = 'Name is required';
        }
        if (name === 'phone' && value && !/^[0-9]{8,10}$/.test(value)) {
            error = 'invalid phone number';
        }
        if (name === 'email' && (!value || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))) {
            error = 'invalid email';
        }
        if (name === 'message' && !value) {
            error = 'Message is required';
        }

        return error;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    const validateForm = () => {
        const newErrors = Object.keys(formValues).reduce((acc, field) => {
            const error = validateField(field, formValues[field]);
            if (error) acc[field] = error;
            return acc;
        }, {} as typeof errors);

        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === '');
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const isFormValid = validateForm();
        if (isFormValid) {

            const evensKey = "04151b70-3170-4927-bbf0-f519bc368581"
            // const ariansKey = "5dd77f5f-ad9a-41e7-9869-4a43e7f072d0"

            const dataWithAccessKey = {
                ...formValues,
                access_key: evensKey
            };
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...dataWithAccessKey
                }),
            });
            const result = await response.json();

            if (result.success) {
                setResult("Form submitted successfully!");
            } else {
                setResult("There was an error submitting the form. Please try again later.");
            }

            closeChat();
        }
    };

    const closeChat = () => {
        setOpen(false);
        setFormValues({
            name: '',
            phone: '',
            email: '',
            message: '',
        });
        setErrors({
            name: '',
            phone: '',
            email: '',
            message: '',
        });
    };

    return (
        <>
            <Snackbar
                open={result !== ""}
                autoHideDuration={4000}
                onClose={() => setResult("")}
                message={result}
            />

            {open ?
                (
                    <Wrapper>
                        <Header>
                            <Typography variant="h6" gutterBottom>
                                Contact me
                            </Typography>
                            <IconButton onClick={closeChat}>
                                <ExpandMore />
                            </IconButton>
                        </Header>

                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={2}>
                                <Grid size={12}>
                                    <StyledTextField
                                        required
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.name}
                                        onChange={handleInputChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <StyledTextField
                                        name="phone"
                                        label="Phone number (8 digits)"
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.phone}
                                        onChange={handleInputChange}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <StyledTextField
                                        required
                                        name="email"
                                        label="E-mail"
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.email}
                                        onChange={handleInputChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <StyledTextField
                                        required
                                        name="message"
                                        label="Message"
                                        multiline
                                        rows={6}
                                        fullWidth
                                        variant="outlined"
                                        value={formValues.message}
                                        onChange={handleInputChange}
                                        error={!!errors.message}
                                        helperText={errors.message}
                                    />
                                </Grid>
                                <Grid size={5.5} offset={6.5}>
                                    <StyledButton type="submit" variant="contained" fullWidth>
                                        Send
                                        <SendIcon fontSize="small" />
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Wrapper>
                ) : (
                    <BubbleButton>
                        <IconButton size='large' onClick={() => setOpen(true)}>
                            <ChatBubbleIcon />
                        </IconButton>
                    </BubbleButton>
                )
            }
        </>
    );
}

export default ChatBubble;
