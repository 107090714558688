import React from 'react';

function CvEntry({ data }) {
    if (!data) {
        return null;
    }

    const entries = Object.entries(data).map(([key, val], index) => {
        const { title, description, startYear, endYear, longDescription } = val;
        const entryDescription = description ? `| ${description}` : '';
        const fullYear = `${startYear}${endYear ? ` | ${endYear}` : ''}`;
        const entryLongDescription = longDescription ? (
            <div className='longDesc'>
                <p>{longDescription}</p>
                <p>.</p>
            </div>
        ) : null;

        return (
            <div key={index}>
                <div className='cvRow'>
                    <div className='cvDate'>
                        <p>{fullYear}</p>
                    </div>
                    <div className='cvEntryDetails'>
                        <p><strong>{title}</strong></p>
                        <p>{entryDescription}</p>
                        {entryLongDescription}
                    </div>
                </div>
            </div>
        );
    });

    return <div>{entries}</div>;
}

export default CvEntry;
