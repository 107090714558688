import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import styled from 'styled-components';
import Grid from '@mui/material/Grid2';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const StyledTextField = styled(TextField)``;

const StyledButton = styled(Button)`
    display: flex;
    gap: 8px;
    font-weight: 600!important;
    && {
        background-color: #9b0000; 
        color: #ffffff;
    
        &:hover {
            background-color: #c90000;
        }
    }
`;

const Buttons = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 20px;

    & button {
        color: #8a8a8a;
    }
`;
const StyledForm = styled.form`
`;

interface props {
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    formValues: {
        name: string,
        phone: string,
        email: string,
        message: string,
        city: string,
        address: string,
        postalCode: string
    };
    setFormValues: React.Dispatch<React.SetStateAction<{
        name: string;
        phone: string;
        email: string;
        message: string;
        city: string;
        address: string;
        postalCode: string;
    }>>;
    setIsInCheckout: React.Dispatch<React.SetStateAction<boolean>>;
}

const CartShipping: React.FC<props> = ({ handleSubmit, formValues, setFormValues, setIsInCheckout }) => {

    const [errors, setErrors] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        city: '',
        address: '',
        postalCode: '',
    });

    const validateField = (name: string, value: string) => {
        let error = '';

        if (name === 'name' && !value) {
            error = 'Name is required';
        }
        if (name === 'phone' && value && !/^[0-9]{8,12}$/.test(value)) {
            error = 'invalid phone number. It should be 8-12 digits';
        }
        if (name === 'email' && (!value || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))) {
            error = 'invalid email';
        }
        if (name === 'city' && !value) {
            error = 'City is required';
        }
        if (name === 'address' && !value) {
            error = 'Address is required';
        }
        if (name === 'postalCode' && !value) {
            error = 'Postal code is required';
        }
        if (name === 'postalCode' && value && !/^[0-9]{4}$/.test(value)) {
            error = 'invalid postal code. It should be 4 digits';
        }

        return error;
    };

    const thereAreNoErrors = () => {
        const noErrors = Object.values(errors).every((error) => !error);
        const allFieldsAreFilledExceptMessage = Object.keys(formValues).every((key) => key === 'message' || formValues[key]);

        return noErrors && allFieldsAreFilledExceptMessage;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    return (
        <>
            <StyledForm onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <h6>Personal information</h6>
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="name"
                            label="Full name"
                            fullWidth
                            variant="outlined"
                            value={formValues.name}
                            onChange={handleInputChange}
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="phone"
                            label="Phone number (8 digits)"
                            fullWidth
                            variant="outlined"
                            value={formValues.phone}
                            onChange={handleInputChange}
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />


                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="email"
                            label="E-mail"
                            fullWidth
                            variant="outlined"
                            value={formValues.email}
                            onChange={handleInputChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Grid>
                    <Grid size={12}>
                        <h6>Shipping address</h6>
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="country"
                            label="Country (Shipping is only available in Norway)"
                            value="Norway"
                            disabled
                            fullWidth
                            variant="outlined"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="city"
                            label="City"
                            fullWidth
                            variant="outlined"
                            onChange={handleInputChange}
                            error={!!errors.city}
                            helperText={errors.city}
                        />
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="address"
                            label="Address"
                            fullWidth
                            variant="outlined"
                            onChange={handleInputChange}
                            error={!!errors.address}
                            helperText={errors.address}
                        />
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            required
                            name="postalCode"
                            label="Postal code"
                            fullWidth
                            variant="outlined"
                            onChange={handleInputChange}
                            error={!!errors.postalCode}
                            helperText={errors.postalCode}
                        />
                    </Grid>
                    <Grid size={12}>
                        <h6>Additional information</h6>
                    </Grid>
                    <Grid size={12}>
                        <StyledTextField
                            name="message"
                            label="Addtional notes"
                            multiline
                            rows={6}
                            fullWidth
                            variant="outlined"
                            value={formValues.message}
                            onChange={handleInputChange}
                            error={!!errors.message}
                            helperText={errors.message}
                        />
                    </Grid>
                    <p>
                        * Note that shipping is handled manually and may take a few days. You will receive an invoice by email after the order is placed.
                    </p>
                    <Grid size={8} offset={4}>
                        <Buttons>
                            <Button variant="text" fullWidth color="secondary" onClick={() => setIsInCheckout(false)}>
                                back
                            </Button>
                            <StyledButton type="submit" variant="contained" fullWidth disabled={!thereAreNoErrors()}>
                                Submit order <ShoppingBagIcon />
                            </StyledButton>
                        </Buttons>
                    </Grid>
                </Grid>
                
            </StyledForm>
        </>
    );
};

export default CartShipping;