/**
 * Converts an ISO date string into a readable format (e.g., "12. August 2023").
 * 
 * @param {string} isoDate - The ISO date string to convert.
 * @returns {string} - The formatted date in "day. month year" format.
 */
export const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);

    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };

    return date.toLocaleDateString('en-GB', options);
};

/**
 * Gets the current year.
 * 
 * @returns {number} - The current year as a four-digit number.
 */
export const getCurrentYear = (): number => {
    const currentYear = new Date().getFullYear();
    return currentYear;
};
