import React from 'react';
import Footer from './Components/Footer.tsx'
import './App.css';
import styled from 'styled-components';
import Router from './Router.tsx';
import ChatBubble from './Components/Bubbles/ChatBubble.tsx';
import CartBubble from './Components/Bubbles/Cart/CartBubble.tsx';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CartProvider } from './Context/CartContext.tsx';

const Container = styled.div`
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 80px;
    flex-direction: column;

    @media (max-width: 768px) {
        padding-top: 30px;
    }
`;

const AppBody = styled.div`
    max-width: 950px;
    width: 100%;
`;

const App = () => {


    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Container>
                <CartProvider>
                    <AppBody>
                        <Router />
                    </AppBody>
                    <Footer />
                    <CartBubble />
                    <ChatBubble />
                </CartProvider>
            </Container>
        </ ThemeProvider>
    );
}

export default App;
