import React from 'react';
import { BrowserRouter as Router, NavLink, Routes, Route, Navigate } from 'react-router-dom';
import Project from './Views/Project.tsx';
import Gallery from './Views/Gallery.tsx';
import Bio from './Views/Bio.tsx';
import BlogOverview from './Views/BlogOverview.tsx';
import BlogPost from './Views/BlogPost.tsx';


function Navigation() {
    const routes = [
        { path: "/", name: "EVEN VIGELAND", element: <Navigate to="/shop" replace /> }, // Redirect base route to /shop
        { path: "/shop", name: "SHOP", element: <Gallery /> },
        { path: "/about", name: "ABOUT", element: <Bio /> },
        { path: "/blog", name: "BLOG", element: <BlogOverview /> },
        { path: "/blog/:id", name: "", element: <BlogPost /> }, 
        { path: "/shop/project/:id", name: "", element: <Project /> },
    ];

    return (
        <Router>
            <nav className='nav'>
                {routes.filter(route => route.name).map(({ path, name }, index) => (
                    <div id={`nav${index}`} key={index}>
                        <NavLink
                            to={path}
                            className={({ isActive }) => (isActive ? 'active' : '' )}
                            end
                        >
                            {name}
                        </NavLink>
                    </div>
                ))}
            </nav>
            <Routes>
                {routes.map(({ path, element }, index) => (
                    <Route key={index} path={path} element={element} />
                ))}
            </Routes>
        </Router>
    );
}

export default Navigation;
