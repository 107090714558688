import React, { createContext, useContext, useState, useEffect } from "react";

interface Listing {
    _id: string;
    uuid: string;
    name: string;
    dimentions: string;
    price: number;
    imageUrl: string;
}

interface CartState {
    items: Listing[];
    addItem: (item: Listing) => void;
    removeItem: (id: string) => void;
    clearCart: () => void;
}


const CART_STORAGE_KEY = "cartItems";

const saveCartToLocalStorage = (cartItems: Listing[]) => {
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cartItems));
};

const getCartFromLocalStorage = (): Listing[] => {
    const storedCart = localStorage.getItem(CART_STORAGE_KEY);
    return storedCart ? JSON.parse(storedCart) : [];
};

const CartContext = createContext<CartState | undefined>(undefined);

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error("useCart must be used within a CartProvider");
    }
    return context;
};

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [items, setItems] = useState<Listing[]>([]);

    useEffect(() => {
        const storedCart = getCartFromLocalStorage();
        setItems(storedCart);
    }, []);

    useEffect(() => {
        saveCartToLocalStorage(items);
    }, [items]);

    const addItem = (item: Listing) => {
        setItems((prevItems) => [...prevItems, item]);
    };

    const removeItem = (id: string) => {
        setItems((prevItems) => {
            const index = prevItems.findIndex((item) => item._id === id);
            if (index !== -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(index, 1); 
                return updatedItems;
            }
            return prevItems; 
        });
    };

    const clearCart = () => {
        setItems([]);
    };

    return (
        <CartContext.Provider value={{ items, addItem, removeItem, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
