import React from 'react';
import { Link } from "react-router-dom";

interface props {
    imgUrl: string;
    text: string;
    id: string;
}
function ImageWithTextOnHover({ imgUrl, text, id }: props) {
    return (
        <Link to={"project/" + id}>
            <div className='textOnHover'>
                <div>
                    <h2>{text}</h2>
                </div>
                <img
                    src={imgUrl}
                    alt='click to see project details'
                />
            </div>
        </Link>
    );
}

export default ImageWithTextOnHover;
