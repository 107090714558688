import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

const Wrapper = styled.div`
width: 100%;
border: 1px solid #5c5c5c;
border-radius: 5px;
padding: 15px;
margin: 40px 0;

& p {
    padding-left: 30px;
}
`;

const Payment: React.FC = () => {
    return (
        <Wrapper>
            <h6>Payment method</h6>
            <FormControlLabel disabled control={<Checkbox defaultChecked />} label="Mail invoice" />
            <p>
                As a small, growing business, I'm currently sending invoices manually after you place an order. I appreciate your patience, and I'm excited to share that more convenient payment options are on the way soon!
            </p>
        </Wrapper>
    );
};

export default Payment;