import React, { useEffect, useState } from 'react';
import ListingImages from './ListingContent.tsx';
import { fetchGalleryEntry } from '../../Utils/Api.tsx';
import LoadingSpinner from '../LoadingSpinner.tsx';

function ListingOverview({ id }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        const loadGalleryData = async () => {
            const result = await fetchGalleryEntry(id);
            setData(result?.galleryEntries);
        };
        loadGalleryData();
    }, [id]);

    return data ? <ListingImages data={data} /> : <LoadingSpinner />;
}

export default ListingOverview;
