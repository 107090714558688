import React, { useEffect, useState } from 'react';
import { fetchBlogPosts } from '../Utils/Api.tsx';
import styled from 'styled-components';
import { formatDate } from '../Utils/Helpers.tsx'
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner.tsx';

const BlogCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px; 
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #272727;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        gap: 20px;
        align-items: stretch;
    }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > p {
        opacity: 0.7;
        font-weight: 600;
        margin-top: 10px;
    }
`;

const CardMedia = styled.img`
    width: 170px;
    height: 170px;
    object-fit: cover; 
    transition: transform 0.3s ease; 

    @media (max-width: 768px) {
        width: 100%;
        height: 250px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;


interface BlogPost {
    sys: {
        id: string;
        publishedAt: string;
    };
    title: string;
    summary: string;
    mainImage: {
        url: string;
    };
}

interface BlogPostCollection {
    items: BlogPost[];
}

interface ServerResponse {
    blogPostCollection: BlogPostCollection;
}


const BlogOverview: React.FC = () => {
    const navigate = useNavigate();

    const [data, setData] = useState<BlogPostCollection | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const blogPosts: ServerResponse = await fetchBlogPosts();

                if (blogPosts.blogPostCollection) {
                    setData(blogPosts.blogPostCollection);
                } else {
                    setError("Unexpected data format");
                }
            } catch (error) {
                console.error(error);
                setError("Failed to fetch blog posts");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    if (loading) {
        return (<LoadingSpinner />);
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Wrapper>
            {data && data.items && data.items.length > 0 ? (
                data.items.map((post) => (
                    <BlogCard key={post.sys.id} onClick={() => navigate(`/blog/${post.sys.id}`)}>
                        <CardContent>
                            <div>
                                <h1>{post.title}</h1>
                                <p>{post.summary}</p>
                            </div>
                            <p>{formatDate(post.sys.publishedAt)}</p>
                        </CardContent>
                        <CardMedia src={post.mainImage.url} alt={post.title} />
                    </BlogCard>
                ))
            ) : (<LoadingSpinner />)}
        </Wrapper>
    );
};

export default BlogOverview;
