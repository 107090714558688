import React from 'react'
import {getCurrentYear} from '../Utils/Helpers.tsx'

function Footer() {
    
    return (
        <footer>
            <small>Copyright © {getCurrentYear()} All rights reserved - Even Vigeland - org. 926 555 499 </small>
        </footer>
    )
}

export default Footer;
