import React from 'react'
import ListingEntry from '../Components/Listings/ListingEntry.tsx';
import { useParams } from 'react-router-dom';

const Project = () => {

    const params = useParams();
    const id = params.id;

    return (
        <>
            <ListingEntry
                id={id}
            />
        </>


    );
}

export default Project;